<script>
/**
 * Temperature component
 */
//import moment from 'moment-timezone'
export default {
  props: ["events", "device", "rangeTime", "loader"],
  data() {
    return {
      time: null,
      //loader: true,
      chartOptions: {
        chart: {
          type: "line",
          height: 305,
          zoomType: "x",
        },
        plotOptions: {
          series: {
            turboThreshold: 100000,
            animation: false,
          },
        },
        scrollbar: {
          liveRedraw: false,
        },
        xAxis: {
          type: "datetime",
          zoomEnabled: true,
          /* labels: {
            format: "{value:%B %d}",
        }, */
          minRange: 3600 * 1000,
        },
        yAxis: {
          title: {
            text: this.$t("charts.temperature.text") + " (°C)",
          },
          /* plotLines: [
            {
              color:"red",
              label: {
                formatter: function () {
                    return "Temp" + this.avgTmp + " (°C)";
                }
              },
              width: 2,
              value: 10,
            }
          ], */
          opposite: false,
        },
        tooltip: {
          xDateFormat: "%A, %b %d, %H:%M:%S",
          valueDecimals: 2,
          valueSuffix: " °C",
        },

        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        rangeSelector: {
          buttons: [
            {
              type: "hour",
              count: 1,
              text: "1h",
            },
            {
              type: "day",
              count: 1,
              text: "1d",
            },
            {
              type: "month",
              count: 1,
              text: "1m",
            },
            {
              type: "year",
              count: 1,
              text: "1y",
            },
            {
              type: "all",
              text: "All",
            },
          ],
          inputEnabled: false, // it supports only days
          selected: 5, // all
        },
        lang: {
          noData: this.$t("charts.noData.text"),
        },
        exporting: {
          enabled: true,
          filename: 'température'
        },
        series: [],
      },
    };
  },
  watch: {
    device(newVal) {
      this.chartOptions.title.text = newVal.name + " : " + newVal.serial;
      this.chartOptions.subtitle.text = "Province : " + newVal.province.name;
      this.chartOptions.exporting.filename = `Temperature - ${newVal.serial}`
    },
    events(newVal) {
      //this.loader = true;
      let data = [...newVal];
      this.setSeries(data);
    },
    rangeTime(newVal) {
      this.time = newVal;
    },
  },
  mounted() {
    /* this.loader = true;
    this.setSeries(this.events); */
  },
  computed: {
    avgTmp() {
      let data = [];
      this.events.map(obj=>{
        if(obj.temp !== 127 && obj.temp !== -0.05 && obj.temp !== -0.06){
          data.push(obj)
        }
      });

      const average = (args) => args.reduce((a, b) => a + b) / args.length;
      let arrayEvents = data.map((obj) => {
        return obj.temp;
      });
      if (arrayEvents.length > 0) {
        return average(arrayEvents);
      } else {
        return 0;
      }
    },
    maxTmp() {
      let arrayEvents = [];
      this.events.map(obj=>{
        if(obj.temp !== 127 && obj.temp !== -0.05 && obj.temp !== -0.06){
          arrayEvents.push(obj)
        }
      });
      let max = arrayEvents[0];
      arrayEvents.map((obj) => {
        if (obj.temp >= max.temp) {
          max = obj;
        }
      });
      return max;
    },
    minTmp() {
      let arrayEvents = [];
      this.events.map(obj=>{
        if(obj.temp !== 127 && obj.temp !== -0.05 && obj.temp !== -0.06){
          arrayEvents.push(obj)
        }
      });
      let min = arrayEvents[0];
      arrayEvents.map((obj) => {
        if (obj.temp <= min.temp) {
          min = obj;
        }
      });
      return min;
    },
  },
  methods: {
    setSeries(events) {
      let data = events.reverse();
      let avg = this.avgTmp;
      let max = this.maxTmp;
      let min = this.minTmp;
      let tempName = this.$t("charts.temperature.text");
      let tempAvg = this.$t("charts.temperatureAvg.text");
      let tempMax = this.$t("charts.temperatureMax.text");
      let tempMin = this.$t("charts.temperatureMin.text");

      let eventsData = [];
      if (data.length > 0) {
        for (let item of data) {
          var date = new Date(item.createdAt).getTime() + 60 * 60 * 1000;
          /* var date = Date.parse(moment(item.createdAt)
            .tz("Africa/Casablanca")); */
          if (item.temp !== 127 && item.temp !== -0.05 && item.temp !== -0.06){
            eventsData.push({ x: date, y: item.temp });
          }
        }
        this.chartOptions.series = [
          {
            name: tempName,
            data: eventsData,
            marker: {
              enabled: false,
            },
          },
          {
            id: "maxSeries",
            name: tempMax,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.point.label;
              },
            },
            color: "rgb(230, 0, 0)",
            data: [
              {
                x: new Date(max.createdAt).getTime() + 60 * 60 * 1000,
                y: max.temp,
                label: max.temp + " °C",
              },
            ],
            marker: {
              fillColor: "rgb(230, 0, 0)",
              symbol: "circle",
              radius: 5,
            },
          },
          {
            id: "minSeries",
            name: tempMin,
            dataLabels: {
              enabled: true,
              formatter: function () {
                return this.point.label;
              },
            },
            color: "rgb(0, 230, 0)",
            data: [
              {
                x: new Date(min.createdAt).getTime() + 60 * 60 * 1000,
                y: min.temp,
                label: min.temp + " °C",
              },
            ],
            marker: {
              fillColor: "rgb(0, 230, 0)",
              symbol: "circle",
              radius: 5,
            },
          },
        ];
        this.chartOptions.yAxis.plotLines = [
          {
            color: "red",
            label: {
              formatter: function () {
                return `${tempAvg} ${avg.toFixed(2)}  (°C)`;
              },
              style: {
                fontSize: 13,
                fontWeight: "bold",
              },
            },
            width: 2,
            zIndex: 10,
            value: avg,
          },
        ];
      } else {
        this.chartOptions.series = [
          {
            name: tempName,
            data: eventsData,
          },
        ];
      }
    },
  },
};
</script>

<template>
  <div style="min-height: 304px">
    <div
      v-if="loader == true"
      class="loaderDiv d-flex justify-content-center align-items-center"
    >
      <b-spinner
        label="Loading..."
        variant="primary"
        style="width: 3rem; height: 3rem"
      ></b-spinner>
    </div>
    <div>
      <highcharts
        v-if="loader == false && rangeTime == ''"
        ref="tempChart"
        :options="chartOptions"
      ></highcharts>
    </div>
    <div>
      <highcharts
        v-if="loader == false && rangeTime != ''"
        :constructor-type="'stockChart'"
        :options="chartOptions"
      ></highcharts>
    </div>
  </div>
</template>
<style scoped>
.loaderDiv {
  position: absolute;
  z-index: 5000;
  width: 100%;
  height: 65%;
  background: rgba(255, 255, 255, 0.4);
}
</style>